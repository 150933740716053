import { formatDate } from 'Utilities/formatters';

import { createNumberWithDisplayValue } from '../helpers/createNumberWithDisplayValue';
import { getHighestValidBidOrOffer } from '../helpers/getHighestValidBidOrOffer';
import type { GetVehicle } from '../helpers/vehicle.schema';
import { FALLBACK } from '../schemas/schema.const';

const getLatestUserBidOrOffer = (bids: GetVehicle['bids']) => {
	const userBid = bids.usersBids[0];

	if (userBid) {
		return {
			bidOrOfferAmount: createNumberWithDisplayValue(userBid.value),
			bidOrOfferId: userBid.id,
		};
	}

	return null;
};

export const biddingSectionTransformer = (vehicle: GetVehicle) => ({
	activeBidCount: vehicle.bids.activeBidCount ?? null,
	displayPrice: createNumberWithDisplayValue(vehicle.displayPrice),
	expiryDate: vehicle.expiryDate ?? null,
	hasDealershipWonVehicleAndSellerConfirmed: Boolean(vehicle.seller.postcode),
	hasOtherContactActiveBid: vehicle.hasOtherContactActiveBid,
	highestBidOrOffer: getHighestValidBidOrOffer({ allBids: vehicle.allBids, stateSlug: vehicle.state.slug }),
	isOwnedByUserDealership: vehicle.isOwnedByUserDealership ?? null,
	isVatQualifying: vehicle.isVatQualifying ?? null,
	latestUserBidOrOffer: getLatestUserBidOrOffer(vehicle.bids),
	legacyBids: { usersBids: vehicle.bids.usersBids },
	minimumBid: vehicle.minimumBid,
	prettyVrm: vehicle.prettyVrm,
	priceAverageCondition: vehicle.priceAverageCondition
		? createNumberWithDisplayValue(vehicle.priceAverageCondition)
		: null,
	priceGoodCondition: vehicle.priceGoodCondition ? createNumberWithDisplayValue(vehicle.priceGoodCondition) : null,
	priceReserve: createNumberWithDisplayValue(vehicle.sellerWillAcceptPrice),
	priceRetail: vehicle.priceRetail ? createNumberWithDisplayValue(vehicle.priceRetail) : null,
	priceSource: vehicle.priceSource ?? null,
	priceUpdatedAt: vehicle.priceUpdatedAt ?? null,
	soldDate: vehicle.soldDate ? formatDate(vehicle.soldDate) : null,
	soldFor: vehicle.soldFor ?? null,
	spec: {
		bodyCategory: vehicle.spec.bodyCategory ?? '', // nullable in stage
		firstRegistered: vehicle.spec.firstRegistered,
		fuel: vehicle.spec.fuel,
		height: vehicle.spec.height,
		make: vehicle.spec.make,
		model: vehicle.spec.model,
		transmission: vehicle.spec.transmission ?? FALLBACK,
		vin: vehicle.spec.vin,
	},
	vrm: vehicle.vrm,
});
