import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';

export const EN_CUSTOM_RELATIVE_TIME_LOCALE = 'en-custom-relative-time';
export const EN_VEHICLE_COUNTDOWN_LOCALE = 'en-vehicle-countdown-time';

// dayjs as instance issue is open on GitHub: https://github.com/iamkun/dayjs/issues/655;

dayjs.extend(UTC);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

// https://day.js.org/docs/en/display/from-now#list-of-breakdown-range
dayjs.extend(relativeTime, {
	thresholds: [
		{ d: 'second', l: 's', r: 59 },
		{ l: 'm', r: 1 },
		{ d: 'minute', l: 'mm', r: 59 },
		{ l: 'h', r: 1 },
		{ d: 'hour', l: 'hh', r: 23 },
		{ l: 'd', r: 1 },
		{ d: 'day', l: 'dd', r: 29 },
		{ l: 'M', r: 1 },
		{ d: 'month', l: 'MM', r: 11 },
		{ l: 'y' },
		{ d: 'year', l: 'yy' },
	],
});

// https://day.js.org/docs/en/customization/customization
/* eslint-disable sort-keys-fix/sort-keys-fix */
const vehicleCountdownLocale = {
	name: EN_VEHICLE_COUNTDOWN_LOCALE,
	relativeTime: {
		future: '%s left',
		past: '%s ago',
		s: 'Less than 1m',
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: 'a day',
		dd: '%d days',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	},
};
const customRelativeTimeLocale = {
	name: EN_CUSTOM_RELATIVE_TIME_LOCALE,
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: '<1 min',
		m: '1 min',
		mm: '%d mins',
		h: '1 hour',
		hh: '%d hours',
		d: 'undefined',
		dd: 'undefined',
		M: 'undefined',
		MM: 'undefined',
		y: 'undefined',
		yy: 'undefined',
	},
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

// load locales for future usage
dayjs.locale(vehicleCountdownLocale, null, true);
dayjs.locale(customRelativeTimeLocale, null, true);

export default dayjs;
