import imageCategories from 'Utilities/imageCategories.json';

export const IMAGE_KIND = {
	additionalDocuments: 'additional_documents',
	bankStatement: 'bank_statement',
	damageAlloyScuffs: 'damage_alloy_scuffs',
	damageDents: 'damage_dents',
	damageMissingTrims: 'damage_missing_trims',
	damagePaintwork: 'damage_paintwork',
	damageScratches: 'damage_scratches',
	damageScuffs: 'damage_scuffs',
	damageTyres: 'damage_tyres',
	damageWarningLights: 'damage_warning_lights',
	damageWindscreen: 'damage_windscreen',
	deathCertificate: 'death_certificate',
	docsServiceHistory: 'docs_service_history',
	drivingLicence: 'driving_licence',
	exteriorFrontDriver: 'exterior_front_driver',
	exteriorFrontPassenger: 'exterior_front_passenger',
	exteriorRearDriver: 'exterior_rear_driver',
	exteriorRearPassenger: 'exterior_rear_passenger',
	financeSettlementLetter: 'finance_settlement_letter',
	instrumentCluster: 'instrument_cluster',
	interiorBoot: 'interior_boot',
	interiorDashboard: 'interior_dashboard',
	interiorFrontSeats: 'interior_front_seats',
	interiorRearSeats: 'interior_rear_seats',
	letterOfConsent: 'letter_of_consent',
	passport: 'passport',
	proofOfPurchase: 'proof_of_purchase',
	solicitorsLetter: 'solicitors_letter',
	tyreTreadFrontDriver: 'tyre_tread_front_driver',
	tyreTreadFrontPassenger: 'tyre_tread_front_passenger',
	tyreTreadRearDriver: 'tyre_tread_rear_driver',
	tyreTreadRearPassenger: 'tyre_tread_rear_passenger',
	utilityBill: 'utility_bill',
	v5: 'v5',
	wheelsFrontDriver: 'wheels_front_driver',
	wheelsFrontPassenger: 'wheels_front_passenger',
	wheelsRearDriver: 'wheels_rear_driver',
	wheelsRearPassenger: 'wheels_rear_passenger',
	will: 'will',
};

export const IMAGES_ORDER = [
	IMAGE_KIND.exteriorFrontDriver,
	IMAGE_KIND.exteriorRearDriver,
	IMAGE_KIND.exteriorFrontPassenger,
	IMAGE_KIND.exteriorRearPassenger,
	IMAGE_KIND.interiorFrontSeats,
	IMAGE_KIND.interiorRearSeats,
	IMAGE_KIND.interiorDashboard,
	IMAGE_KIND.instrumentCluster,
	IMAGE_KIND.interiorBoot,
	// Docs
	IMAGE_KIND.docsServiceHistory,
	// Damage
	IMAGE_KIND.damageAlloyScuffs,
	IMAGE_KIND.damageDents,
	IMAGE_KIND.damageMissingTrims,
	IMAGE_KIND.damagePaintwork,
	IMAGE_KIND.damageScratches,
	IMAGE_KIND.damageScuffs,
	IMAGE_KIND.damageTyres,
	IMAGE_KIND.damageWarningLights,
	IMAGE_KIND.damageWindscreen,
	// Wheels
	IMAGE_KIND.wheelsFrontDriver,
	IMAGE_KIND.wheelsRearDriver,
	IMAGE_KIND.wheelsFrontPassenger,
	IMAGE_KIND.wheelsRearPassenger,
	// Tyres
	IMAGE_KIND.tyreTreadFrontDriver,
	IMAGE_KIND.tyreTreadRearDriver,
	IMAGE_KIND.tyreTreadFrontPassenger,
	IMAGE_KIND.tyreTreadRearPassenger,
];

export const IMAGE_INFO_MAP = imageCategories.reduce(
	(acc, it) => ({ ...acc, [it.kind]: it }),
	{} as Record<string, (typeof imageCategories)[number]>,
);

export const PURCHASE_SUMMARY_IMAGES = [IMAGE_KIND.exteriorFrontDriver, IMAGE_KIND.docsServiceHistory];
