import { IMAGE_INFO_MAP, IMAGE_KIND, IMAGES_ORDER, PURCHASE_SUMMARY_IMAGES } from './images.const';
import type { VehicleImage } from './vehicle.schema';

interface Presets {
	desktop: string;
	mobile: string;
	thumbnail: string;
	thumbnailTiny: string;
}

const IMAGE_WITH_NO_KIND = 'error_no_provided_kind';

const mapVehicleImage = (image: VehicleImage) => ({
	...image,
	category: IMAGE_INFO_MAP[image.kind ?? IMAGE_WITH_NO_KIND]?.type,
	kind: image.kind ?? IMAGE_WITH_NO_KIND,
	presets: image.presets.reduce((acc, it) => ({ ...acc, [it.name]: it.url }), {} as Presets),
	presetsArray: image.presets,
});

const sortVehicleImages = (a: VehicleImage, b: VehicleImage) => {
	const kindComparison =
		IMAGES_ORDER.indexOf(a.kind ?? IMAGE_WITH_NO_KIND) - IMAGES_ORDER.indexOf(b.kind ?? IMAGE_WITH_NO_KIND);
	if (kindComparison !== 0) {
		return kindComparison;
	}
	return a.id - b.id;
};

const isVehicleImageIncluded = (image: VehicleImage) => IMAGES_ORDER.includes(image?.kind ?? IMAGE_WITH_NO_KIND);

export const mapImages = (vehicleImages: VehicleImage[]) => {
	const images = vehicleImages.filter(isVehicleImageIncluded).sort(sortVehicleImages).map(mapVehicleImage);
	const categories = images.reduce(
		(acc, it) => ({ ...acc, [it.category]: (acc[it.category] || 0) + 1 }),
		{} as Record<string, number>,
	);
	return { categories, images };
};

const isPurchaseSummaryImage = (image: VehicleImage) =>
	PURCHASE_SUMMARY_IMAGES.includes(image?.kind ?? IMAGE_WITH_NO_KIND);

export const mapPurchasePageImages = (vehicleImages: VehicleImage[]) => {
	const images = vehicleImages.filter(isPurchaseSummaryImage).map(mapVehicleImage);
	return {
		documents: images.filter(({ kind }) => kind === IMAGE_KIND.docsServiceHistory),
		mainImage: images.find(({ kind }) => kind === IMAGE_KIND.exteriorFrontDriver),
	};
};
